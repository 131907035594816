import React from 'react'
import { BsFacebook } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { BsTwitterX } from "react-icons/bs";
import { BsFillTelephoneFill } from "react-icons/bs";
import { BsEnvelopeFill } from "react-icons/bs";
import { BsFillPinFill } from "react-icons/bs";
import { FaLocationDot } from "react-icons/fa6";


const Footer = () => {
  return (
    <>
       <div className="container-fluid" id='Footer'>
<div className="row p-5">
    <div className="col-lg-4">
        <h3>Connect With Us</h3>
        <br/>
        <a href="" target="_blank" id='Headericons'><BsFacebook/></a><br/>
        <a href=""target="_blank" id='Headericons'><BsInstagram/></a><br/>
        <a href=""target="_blank"id='Headericons'><BsTwitterX/></a><br/>
    </div>

    <div className="col-lg-4">
        <h3>Our Products</h3>
        <br/>
        <p>Medical Bed</p>
        <p>Medical oxygen cylinder</p>
        <p>Operation Bed</p>
        <p>Wheelchair</p>
        <p>Glucometer</p>
    </div>
    
    <div className="col-lg-4">
        <h3>Contact Us</h3>
        <br/>
        <a href="https://maps.app.goo.gl/5URHdDi7Ab4YUjo1A"target="_blank"id='Headericons'><FaLocationDot/></a> <p>Office No, 311 3rd Floor Zarooni Building International City Dubai, UAE.</p>
        <a href="tel:97142943364"target="_blank"id='Headericons'><BsFillTelephoneFill/></a><p>04-2943364</p>
        <a href="mailto:info@yzamedicalequipment.ae"target="_blank"id='Headericons'><BsEnvelopeFill/></a><p>info@yzamedicalequipment.ae</p>
    </div>
    </div>
    </div>
    </>
  )
}

export default Footer


