import React from 'react';
import './Header.css';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { BsFacebook } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { BsTwitterX } from "react-icons/bs";

const Header = () => {
  return (
    <div className="header">
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand to="#home"></Navbar.Brand>       
          <img src="img/Logi.png" id='Logo'/>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Link to="/Home">HOME</Link>
              <Link to="/About">ABOUT</Link>
              <Link to="/Products">PRODUCTS</Link>
              <Link to="/Contact">CONTACT US</Link>
            </Nav>
            <a href="" target="_blank" id='Headericons'><BsFacebook/></a>
            <a href=""target="_blank" id='Headericons'><BsInstagram/></a>
            <a href=""target="_blank"id='Headericons'><BsTwitterX  /></a>
          </Navbar.Collapse>
          
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
