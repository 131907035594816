import React from 'react'

import Carousel from 'react-bootstrap/Carousel';

const Home = () => {
  return (
    <>

    {/* Slider Start */}
    <Carousel>
      <Carousel.Item>
        <img src="/img/1.jpg" alt="" className='img-fluid' />
              <Carousel.Caption>
          {/* <h3>Welcome to Minshas Brothers</h3>  */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img src="/img/2.jpg" alt="" className='img-fluid' />
        <Carousel.Caption>
          {/* <h3>Clearing Agent</h3>  */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img src="/img/3.jpg" alt="" className='img-fluid' />
        <Carousel.Caption>
          {/* <h3>General Order Supplier</h3> */}
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
{/* 
Slider End */}

  {/* <!-- whatsapp start --> */}
  <div className="whatsapp-float">
    <a href="https://wa.me/+971558278249" target="_blank"><img src="img/whatsapp.png" alt="" width="50px" class="whatsapp-float-btn"/></a>
  </div>


  


      {/* <!-- Whatsapp end --> */}


{/* Products Start */}
<div className="container-fluid p-5">
          <h1 className='text-center p-5'>OUR PRODUCTS</h1>
          <div className="row">
            <div className="col-lg-4">
            <div class="image-container">
              <img className='img-fluid rounded' src="img/4.jpg" alt="" width='100%'/>
              <h5 className='text-center'>Shock Wave Heater</h5>
              </div>
              </div>

            <div className="col-lg-4">
            <div class="image-container">
            <img className='img-fluid rounded' src="img/5.jpg" alt="" width='100%'/>
              <h5 className='text-center'>Surgeon Set</h5>
            </div>
            </div>
            
            <div className="col-lg-4" id='card'>
            <div class="image-container">
            <img className='img-fluid rounded' src="img/6.jpeg" alt="" width='100%'/>
              <h5 className='text-center'>Medical Bed</h5>
            </div>
            </div>
            <div className="col-lg-4">
            <div class="image-container">
            <img className='img-fluid rounded' src="img/7.jpg" alt="" width='100%'/>
              <h5 className='text-center'>Doctor Set</h5>
            </div>
            </div>
            <div className="col-lg-4">
            <div class="image-container">
            <img className='img-fluid rounded' src="img/8.jpeg" alt="" width='100%'/>
              <h5 className='text-center'>Operation Bed</h5>
            </div>
            </div>
            <div className="col-lg-4">
            <div class="image-container">
            <img className='img-fluid rounded' src="img/1.jpg" alt="" width='100%'/>
              <h5 className='text-center'>medical oxygen cylinder</h5>
            </div>
            </div>
            <div className="col-lg-4">
            <div class="image-container">
            <img className='img-fluid rounded' src="img/2.jpg" alt="" width='100%'/>
              <h5 className='text-center'>Glucometer</h5>
            </div>
            </div>
            <div className="col-lg-4">
            <div class="image-container">
            <img className='img-fluid rounded' src="img/11.jpg" alt="" width='100%'/>
              <h5 className='text-center'>Syringe Pump</h5>
            </div>
            </div>
            <div className="col-lg-4">
            <div class="image-container">
            <img className='img-fluid rounded' src="img/12.jpg" alt="" width='100%'/>
              <h5 className='text-center'>Wheelchair</h5>
            </div>
            </div>
          </div>
        </div>

{/* Products end */}




<div className="container-fluid p-5">
<div className="row">
<div className="col-lg-6">
  <img src="/img/13.jpeg" className='img-fluid rounded'/>
</div>
<div className="col-lg-6">
<h1>Yousaf Zai Al Khair Medical Equipment</h1>
  <p>Yousaf Zai Al Khair Medical Equipment stands as a beacon of innovation and excellence in the realm of healthcare solutions. Established with a commitment to providing top-notch medical equipment, the company has emerged as a trusted name in the industry. With a focus on quality, reliability, and customer satisfaction.</p>
</div>
</div>
</div>


<div className="container-fluid p-5">
<div className="row">

<div className="col-lg-6">
<img src="/img/14.jpeg" alt="" className='img-fluid rounded'/>
</div>
<div className="col-lg-6">
  <h1>Yousaf Zai Al Khair Medical Equipment</h1>
  <p>The company's commitment to sustainability and ethical business practices sets it apart in the industry. Yousaf Zai Al Khair Medical Equipment not only focuses on providing high-quality products but also ensures that its operations are environmentally friendly and socially responsible.As a leader in the field, Yousaf Zai Al Khair Medical Equipment continues to push boundaries.</p>
</div>

</div>
</div>



<div className="container-fluid bg d-flex align-items-center justify-content-center">
<div className="container">
<h1 className='text-center'>Yousaf Zai Al Khair Medical Equipment</h1>
</div>
</div>


<div className="container-fluid bg1 d-flex align-items-center justify-content-center">
<div className="container">
<h1 className='text-center'>Your Medical Equipment Supplier</h1>
</div>
</div>


<div className="container-fluid p-5">
  <div className="row">
    <div className="col-lg-6">
      <img src="/img/17.jpeg" alt="" className='img-fluid rounded' />
    </div>
    <div className="col-lg-6">
      <h1>Why Choose Us for Medical Equipment Supply</h1>
      <p>Choose us for unparalleled quality, reliability, and customer satisfaction. Our commitment to innovation ensures cutting-edge solutions. We prioritize sustainability and ethical practices. With Yousaf Zai Al Khair Medical Equipment, you get excellence, trust, and innovation for all your medical equipment needs.</p>
    </div>
  </div>
</div>


<div className="container-fluid p-5">
  <div className="row">
    <div className="col-lg-6">
      <img src="/img/18.jpg" alt="" className='img-fluid rounded' />
    </div>
    <div className="col-lg-6">
      <h1>Quality of Our Medical Equipment's</h1>
      <p>Our medical equipment sets the industry standard. Rigorously tested, it ensures reliability and accuracy. From diagnostics to life-saving devices, we guarantee excellence. Yousaf Zai Al Khair Medical Equipment is your trusted partner, providing top-notch solutions for all your healthcare needs.</p>
    </div>
  </div>
</div>





    </>
  )
}

export default Home
