import React from 'react'
import { FaServicestack} from "react-icons/fa6";
import { FaFileImport} from "react-icons/fa6";
import { FaPlaceOfWorship} from "react-icons/fa6";
import { FaTrainp} from "react-icons/fa6";
import { FaTruckFront} from "react-icons/fa6";
import { FaShip} from "react-icons/fa6";
import { FaBuildingUser} from "react-icons/fa6";

const Services = () => {
  return (
    <>
    
    <h1 className='text-center'>Products</h1>
    {/* <!-- whatsapp start --> */}
  <div className="whatsapp-float">
  <a href="https://wa.me/+971558278249" target="_blank"><img src="img/whatsapp.png" alt="" width="50px" class="whatsapp-float-btn"/></a>
  </div>
      {/* <!-- Whatsapp end --> */}
    

    {/* Products Start */}
<div className="container-fluid p-5">
         
          <div className="row">
            <div className="col-lg-4">
            <div class="image-container">
              <img className='img-fluid rounded' src="img/4.jpg" alt="" width='100%'/>
              <h5 className='text-center'>Shock Wave Heater</h5>
              </div>
              </div>             

            <div className="col-lg-4">
            <div class="image-container">
            <img className='img-fluid rounded' src="img/5.jpg" alt="" width='100%'/>
              <h5 className='text-center'>Surgeon Set</h5>
            </div>
            </div>
            
            <div className="col-lg-4" id='card'>
            <div class="image-container">
            <img className='img-fluid rounded' src="img/6.jpeg" alt="" width='100%'/>
              <h5 className='text-center'>Medical Bed</h5>
            </div>
            </div>
            <div className="col-lg-4">
            <div class="image-container">
            <img className='img-fluid rounded' src="img/7.jpg" alt="" width='100%'/>
              <h5 className='text-center'>Doctor Set</h5>
            </div>
            </div>
            <div className="col-lg-4">
            <div class="image-container">
            <img className='img-fluid rounded' src="img/8.jpeg" alt="" width='100%'/>
              <h5 className='text-center'>Operation Bed</h5>
            </div>
            </div>
            <div className="col-lg-4">
            <div class="image-container">
            <img className='img-fluid rounded' src="img/1.jpg" alt="" width='100%'/>
              <h5 className='text-center'>medical oxygen cylinder</h5>
            </div>
            </div>
            <div className="col-lg-4">
            <div class="image-container">
            <img className='img-fluid rounded' src="img/2.jpg" alt="" width='100%'/>
              <h5 className='text-center'>Glucometer</h5>
            </div>
            </div>
            <div className="col-lg-4">
            <div class="image-container">
            <img className='img-fluid rounded' src="img/11.jpg" alt="" width='100%'/>
              <h5 className='text-center'>Syringe Pump</h5>
            </div>
            </div>
            <div className="col-lg-4">
            <div class="image-container">
            <img className='img-fluid rounded' src="img/12.jpg" alt="" width='100%'/>
              <h5 className='text-center'>Wheelchair</h5>
            </div>
            </div>
          </div>
        </div>

{/* Products end */}
          
         
    
    </>

    

  )
}

export default Services
