import React from 'react'

const Contact = () => {
  return (
    <>


<h1 className='text-center'>Contact Us</h1>

{/* <!-- whatsapp start --> */}
<div className="whatsapp-float">
<a href="https://wa.me/+971558278249" target="_blank"><img src="img/whatsapp.png" alt="" width="50px" class="whatsapp-float-btn"/></a>
  </div>
      {/* <!-- Whatsapp end --> */}

<div className="container-fluid p-5">
<div className="row">
<div className="col-lg-6">
  <img src="/img/17.jpeg" alt="" className='img-fluid rounded'/>
</div>
<div className="col-lg-6">
<h3>Office Address</h3>
      <p>Office No, 311 3rd Floor Zarooni Building International City Dubai, UAE.</p>
      <h3>Email:</h3>
      <p>info@yzamedicalequipment.ae</p>
      <h3>Call:</h3>
      <p>04-2943364</p>
</div>

</div>
<div className="container-fluid p-5" id='map'>

  <h1 className='text-center'>Our Location</h1>
  <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d57772.559275623535!2d55.33523797988889!3d25.176738044764285!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f61e3a9872943%3A0xcfdf847d21f6dfb0!2sAl%20Zaroni%20residential%20building!5e0!3m2!1sen!2sae!4v1713952879653!5m2!1sen!2sae"
        width="100%"
        height="400"
        style={{ border: '0' }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
  
</div>
</div>
    
      {/* <div className="container-fluid">

<div className="container-fluid">
<div class="image-container">
<img className='img-fluid rounded' src="img/17.jpeg" alt="" width='100%'/>
</div>
</div>


<div className="container-fluid p-5">
  <div className="row">
    <div className="col-lg-6">
      <h3>Location</h3>
      <p>Office No, 106 1st Floor Abdullah Squire New Chali Saddar Karachi Pakistan.</p>
      <h3>Email:</h3>
      <p>Ansar@minhasbrother.com</p>
      <h3>Call:</h3>
      <p>0092-300-2317180</p>
    </div>
  </div>
</div>
        
    </div> */}
</>
  )
}

export default Contact
