import './App.css';
import Header from './Components/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './Components/Home';
import About from './Components/About';
import Products from './Components/Products';
import Contact from './Components/Contact';
import Footer from './Components/Footer';





import { BrowserRouter,
  Route,
  Routes,
  route
} from "react-router-dom";





function App() {
  return (
    <>
  <Header/>
  
  <Routes>
    <Route index element={<Home />}/>
      <Route path='Home' element={<Home />}/>
      <Route path='About' element={<About />}/>
      <Route path='Products' element={<Products />}/>
      <Route path='Contact' element={<Contact />}/> 
    </Routes>
   <Footer/>
    </>
  );
}

export default App;
