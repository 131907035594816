import React from 'react'


const About = () => {
  return (
    <>
<h1 className='text-center'>About Us</h1>

<img src="/img/14.jpg" alt="" className='img-fluid'/>



{/* <!-- whatsapp start --> */}
<div className="whatsapp-float">
<a href="https://wa.me/+971558278249" target="_blank"><img src="img/whatsapp.png" alt="" width="50px" class="whatsapp-float-btn"/></a>
  </div>
      {/* <!-- Whatsapp end --> */}


      <div className="container-fluid p-5">
<div className="row">

<div className="col-lg-6">
    <img src="/img/13.jpeg" alt="" className='img-fluid rounded' />
  </div>



  <div className="col-lg-6">
    <h1>Yousaf Zai Al Khair Medical Equipment Services Co L.L.C</h1><br/>
    <p>Yousaf Zai Al Khair Medical Equipment is a leading provider of medical equipment, committed to revolutionizing healthcare solutions. Founded on the principles of quality, reliability, and innovation, we have emerged as a trusted name in the medical equipment industry. With a dedication to enhancing patient care and improving healthcare outcomes, we offer a wide range of cutting-edge medical devices tailored to meet the diverse needs of healthcare facilities worldwide</p>
  </div>
  
</div>
      </div>



      <div className="container-fluid p-5">
<div className="row">
  <div className="col-lg-6">
  <img src="/img/14.jpeg" alt="" className='img-fluid rounded' />
  </div>
  <div className="col-lg-6">
    <h1>Quality and Reliability:</h1>
    <p>At Yousaf Zai Al Khair Medical Equipment, quality is paramount. We adhere to the highest standards of manufacturing and testing to ensure the reliability and accuracy of our medical equipment. Rigorous testing processes guarantee that our products meet or exceed industry standards, providing healthcare professionals with the confidence they need to deliver the best possible care to their patients.</p>
  </div>

</div>

      </div>

      
    </>
  )
}

export default About
